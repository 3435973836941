.contact-section {
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}
.contact-form {
    display: flex;
    flex-direction: column;
    width: 75%;
}

.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    border-style: none;
    background-color: #007ea7;
    border-radius: 14px;
    padding: 25px;
}

.contact-link {
    font-weight: 500;
    font-size: 22px;
    padding-left: 5px;
    height: 25px;
    width: 100%;
    margin: 10px;
    color: white;
    text-decoration: none;
}

.contact-link:hover {
    font-weight: 600;
    text-decoration: underline;
}

@media (max-width: 900px) {
    .contact-link {
        font-size: 18px;
    }

    .form-container {
        padding: 20px;
    }
}

@media (max-width: 610px) {
    .contact-link {
        font-size: 16px;
    }

    .form-container {
        padding: 16px;
        width: 75%;
    }
}
