.cc-navbar {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.site-info-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.theme-btn {
    background-color: transparent;
    border-style: none;
    cursor: pointer;
    margin-right: 50px;
    padding: 0px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.theme-icon {
    background-color: transparent;
    width: 30px;
    height: 30px;
}

.logo {
    max-width: 50px;
    max-height: 50px;
    border-radius: 20px;
    margin-right: 10px;
}

.cc-navbar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.cc-navbar-container .hidden {
    display: none;
    visibility: hidden;
}

.hamburger-menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.hamburger-menu .icon {
    cursor: pointer;
    height: 35px;
    width: 35px;
}

.tabs-container {
    background-color: #007ea7;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.9;
}

.close-navbar {
    position: fixed;
    top: 0;
    right: 0;
    padding-top: 25px;
    padding-right: 50px;
    cursor: pointer;
    color: white;
    font-weight: 400;
    font-size: 1.5em;
}

.close-navbar:hover {
    color: #12130f;
    font-weight: 800;
}

.nav-tab {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 5px;
    border-radius: 20px;
}

.nav-tab:hover {
    background-color: #12130f;
}

.nav-link {
    text-decoration: none;
    color: white;
    font-weight: 600;
    font-size: 1.5em;
    padding: 5px;
}

.nav-link:hover {
    font-weight: 400;
}
