.body {
    text-align: center;
    --background-color: white;
    --text-color: #282c34;
    --theme-selector-color: transparent;
}

.body.dark {
    --background-color: #1f1f1f;
    --text-color: white;
    --theme-selector-color: #ffffff;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: var(--background-color);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    color: var(--text-color);
}
