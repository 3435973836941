.technical-skills-section {
    padding: 20px;
    min-height: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 50px;
}

.section-header {
    font-weight: 600;
    font-size: 20px;
}

.skills-selector-container {
    display: flex;
    flex-direction: row;
    width: 60%;
    justify-content: space-between;
}

.skills-category-selector {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 12px;
}

.category-label {
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
}

.category-label:hover {
    text-shadow: 1px 0 0;
    text-decoration: underline;
}

.selected {
    text-shadow: 1px 0 0;
    color: #007ea7;
}

.skills-container {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 10em;
    column-gap: 30px;
    width: 80%;
    justify-content: center;

    flex-direction: row;
    margin-top: 10px;
}

.skill-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

.skill-label {
    font-weight: 600;
    font-size: 16px;
    margin: 10px;
}

.skill-icon {
    color: var(--skill-icon-color);
}

@media (max-width: 900px) {
    .category-label {
        font-size: 18px;
    }
}

@media (max-width: 750px) {
    .skill-label {
        font-size: 14px;
    }

    .category-label {
        font-size: 16px;
    }
}

@media (max-width: 610px) {
    .skills-container {
        margin-top: 25px;
    }

    .skills-selector-container {
        width: 95%;
    }
}

@media (max-width: 400px) {
}
